<template>
  <router-view/>
</template>

<script>
import DocMixin from '../mixin'

export default {
  name: "DocumentosMain",
  mixins: [DocMixin]
}
</script>
